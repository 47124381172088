@import './variables/mixins';
@import './variables/variables';


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');


/* basic global rules*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  // font-weight: bold;
  user-select: none;
}

body, html {
  height: 100vh;
  width: 100%;
}
body {
  background-color: black;
}
/* 
  Overriding some material dialog styles to fit our needs. used as an alert dialog.
  Not the best of practices, but required for this  */
.mat-dialog-container {
  background: black !important;
  border: solid 4px $color-dialog-background;
}

/** Media queries. Using the same breakpoints as Angular/flex. */
@include xsFlex {
  html {
    font-size: 10px;
  }
  // on smaller screens, the image should be centered to achieve similar visual presentation as in larger screens
  .main-background {
    background-position: 0 0;
  }
} 
@include smFlex {
  html {
    font-size: 12px;
  }
  // on smaller screens, the image should be centered to achieve similar visual presentation as in larger screens
  .main-background {
    background-position: 0 0;
  }
}
@include mdFlex {
  html {
    font-size: 13px;
  }
}

#install-instructions {
  text-align: center;
  padding: 5%; 
  &-general {
      &-paragraph-1 {
          color: $color-text-secondary !important;
      }
  }
  .sub-paragraph {
    color: $color-text-secondary !important;
  }
  .instruction-number {
      @include paragraphNumber;
  }
  &-header {
      font-size: 1.6rem;
      white-space: pre-wrap;
      color: white;
      margin-bottom: 2rem;
  }
  .info-image {
      // using rem with these images also since they are inserted between large amounts of text, which are also using rem
      max-width: 25rem;
  }
  &-mastertig-logo {
      max-width: 10rem;
      vertical-align: middle;
  }
  p {
      font-size: 1.1rem;
      margin-bottom: 6%;
      font-weight: bold;
      white-space: pre-wrap;
      color: $color-text-light;
  }
  .section-header, &-wait {
      color: $color-accent;
      margin-bottom: 0.5rem;
      white-space: pre-wrap;
  }
  &-wait {
      font-size: 1.3rem;
  }
  .section-header {
      font-size: 1.5rem;
  }
  .greenText {
      color: green;
  }
}
#scrollToTop {
  color: $color-text-secondary;
  font-size: 1rem;
  cursor: pointer;
}