/* Color variables, most likely will be moved to the theme once I get the palette done */

$color-primary: #3d3d3d;
$color-primary-hue-1: #1b1b1b;
$color-accent: #ff7300;
$color-white: #ffffff;
$color-accent-darker: #dd6100;
$color-warn: #dd3333;
$color-notice: #ebc90e;
$color-notice-weak: #FFF9C4;
$color-ok:  #5cb20f;
$color-neutral: #c4c4c4;
$color-weak: #a2a2a2;
$color-yet-another-shade-of-gray: #545454;
$color-disabled: #d6d6d6;

$color-background: #f0f0f0;
$color-background-darker: #e6e6e6;
$color-background-inputs: #ffffff;

$color-footer-background: #eaeaea;

$color-light-buttons: #e0e0e0;
$color-light-buttons-selected: #c4c4c4;

$color-text: rgba(0,0,0,0.8);
$color-text-secondary: #a0a0a0;
$color-text-labels: rgba(0, 0, 0, 0.54);
$color-text-light: rgba(255, 255, 255, 1.0);
$color-text-light-secondary: #f2f2f2;
$color-text-light-secondary-darker: darken(#f2f2f2, 5);
$color-icon-light: #fff;
$color-icon: #777;

$color-dialog-title: hsla(0,0%,100%,.87);
$color-dialog-background: #3d3d3d;

$color-required: #ff9033;
$color-underline: rgba(0, 0, 0, 0.12);

$color-accent-transparent: #ff730055;

/** Media query breakpoints*/
$flex-breakpoints: (
    xs: "screen and (max-width: 599px)",
    sm: "screen and (min-width: 600px) and (max-width: 959px)",
    md: "screen and (min-width: 960px) and (max-width: 1279px)",
    lg: "screen and (min-width: 1280px) and (max-width: 1919px)",
    xl: "screen and (min-width: 1920px) and (max-width: 5000px)",
);
  