@import './variables';


@mixin paragraphNumber {
    color: $color-accent !important;
    border: solid 0.15rem;
    border-radius: 1.5rem;
    padding: 0 0.6rem;
    font-weight: bold;
    font-size: 1.5rem;
    height: 2.4rem;
    width: 2.4rem;
}

@mixin buttonStyle {
    border-radius: 1.8rem;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 0.6rem 2rem;
    cursor: pointer;
    transition: box-shadow 0.3s, padding 0.2s;
    &:hover {
        box-shadow: 0 0 0 2px $color-accent;
    }
}

/* angular/Flex breakpoint based media query mixins*/
@mixin xsFlex {
    @media #{map-get($flex-breakpoints, xs)} {
        @content;
    }  
} 
@mixin smFlex {
    @media #{map-get($flex-breakpoints, sm)} {
        @content;
    }
}
@mixin mdFlex {
    @media #{map-get($flex-breakpoints, md)} {
        @content;    
    }   
}
@mixin lgFlex {
    @media #{map-get($flex-breakpoints, lg)} {
        @content;
    }
}
@mixin xlFlex {
    @media #{map-get($flex-breakpoints, xl)} {
        @content;
    }   
}
